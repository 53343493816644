<div class="works">
  <div class="double">
    @for (work of works; track work; let i = $index) {
      @if (i%2 === 0) {
        <div class="left">
          <div class="work">
            <a [routerLink]="'work/' + work.id">
              <img [src]="'assets/works/' + work.image" alt="{{work.title}}"/>
              <h2>{{work.title}}</h2>
              <div class="short-desc">{{work.shortDescription}}</div>
          </a></div>
        </div>
      } @else {
        <div class="right">
          <div class="work">
            <a [routerLink]="'work/' + work.id">
              <img [src]="'assets/works/' + work.image" alt="{{work.title}}"/>
              <h2>{{work.title}}</h2>
              <div class="short-desc">{{work.shortDescription}}</div>
            </a>
          </div>
        </div>
      }
    }
  </div>
</div>
