import { Component, ElementRef, inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { WorkService } from '../../services/work.service';
import { Router, RouterLink } from '@angular/router';
import { WorkModel } from '@shared/models';

@Component({
  selector: 'toro-work',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './work.component.html',
  styleUrl: './work.component.scss'
})
export class WorkComponent implements OnInit{
  works: WorkModel[] = [];

  #workService = inject(WorkService)

  ngOnInit(): void {
    this.#workService.getWorks().subscribe((data: any) => {
      this.works = data;
    })
  }
}
