import { Component } from '@angular/core';
import { ContactComponent } from "../contact/contact.component";
import { WorkComponent } from "../work/work.component";

@Component({
  selector: 'toro-home',
  standalone: true,
  imports: [ContactComponent, WorkComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

}
